import React, { useRef, useCallback, useState } from "react"; //useState, useContext, useRef, useLayoutEffect
import { Navbar, Container, Columns } from "react-bulma-components";
import { Link, useHistory } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { FaUserCircle, FaSignInAlt } from "react-icons/fa";

import style from "./NavBar.module.scss";
import Logo from "../../assets/img/logo.png";
import useResizeObserverHook from "hooks/resizeObserverHook";
import userStore from "contexts/userStore";
import { NavHashLink } from "react-router-hash-link";
import Avatar from "components/Atoms/Avatar";

function UserNav({ isLogged, user, logoutAction }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const pathLogin = window.location.pathname !== "/login";
  const hideButtonLogin = !isLogged && pathLogin;

  return (
    <Navbar.Menu className={style.userMenu}>
      <Navbar.Container renderAs="nav" className={style.userMenu__container}>
        {isLogged && (
          <Navbar.Item dropdown hoverable>
            <Navbar.Link
              renderAs="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleOpen}
            >
              {user.photo ? (
                <Avatar
                  width={28}
                  height={28}
                  rounded={true}
                  src={user.photo}
                />
              ) : (
                <FaUserCircle />
              )}

              <span className={style.profile}>{user.username}</span>
            </Navbar.Link>
            {open ? (
              <Navbar.Dropdown renderAs="ul" role="menu" aria-label="Usuario">
                <li>
                  <Navbar.Item renderAs={Link} to="/perfil">
                    Mi Perfil
                  </Navbar.Item>
                </li>
                <li>
                  <Navbar.Item onClick={logoutAction}>Salir</Navbar.Item>
                </li>
              </Navbar.Dropdown>
            ) : null}
          </Navbar.Item>
        )}
        {hideButtonLogin && (
          <Navbar.Item renderAs={Link} to="/login" className={style.hasSvg}>
            <FaSignInAlt className={style.iconLink} />
            <span>Ingreso</span>
          </Navbar.Item>
        )}
      </Navbar.Container>
    </Navbar.Menu>
  );
}

const MainNav = () => {
  const history = useHistory();
  const user = userStore((state) => state.user);
  const isLogged = userStore((state) => state.isLogged)();
  const logout = useCallback(
    userStore((state) => state.logout),
    []
  );

  const { isMobile } = useResizeObserverHook();

  function userLogout() {
    logout();
    history.push("/");
  }

  const pathHome = window.location.pathname === "/";

  return (
    <Navbar
      className={isMobile ? "mobile" : "non-mobile"}
      role="navigation"
      aria-label="navegación principal"
    >
      <Container>
        <Columns breakpoint="mobile" multiline={false} gapless>
          <Columns.Column size={2} narrow>
            <Navbar.Brand>
              <Navbar.Item renderAs={Link} to="/" alt="Inicio">
                <img
                  className={style.imgage_logo}
                  src={Logo}
                  alt="Logotipo del sitio"
                />
              </Navbar.Item>
            </Navbar.Brand>
          </Columns.Column>
          <Columns.Column className={style.mainMenu}>
            <Navbar.Menu className={style.mainMenu__bar}>
              <Navbar.Container className={style.mainMenu__nav} gapless="true">
                {pathHome && (
                  <>
                    <NavHashLink to="#espacios" className="navbar-item">
                      Espacios
                    </NavHashLink>
                    <NavHashLink to="#edificios" className="navbar-item">
                      Edificios
                    </NavHashLink>
                    <NavHashLink to="#mapa" className="navbar-item">
                      Mapa
                    </NavHashLink>
                  </>
                )}
                {isLogged && (
                  <Navbar.Item
                    renderAs={Link}
                    to="/busqueda/date="
                    aria-label="buscar"
                    className={`is-rounded-icon ${style.search}`}
                  >
                    <MdSearch />
                  </Navbar.Item>
                )}
              </Navbar.Container>
            </Navbar.Menu>
          </Columns.Column>
          <Columns.Column size={2} narrow className={style.userMenu}>
            <UserNav
              isLogged={isLogged}
              user={user}
              logoutAction={userLogout}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </Navbar>
  );
};

export default MainNav;
