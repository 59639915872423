import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import FilteredList from "components/Organisms/FilteredList";
// import { Columns } from "react-bulma-components";
import VenueCard from "components/Cards/VenueCard";
// import { Section, Container } from "react-bulma-components";
import API from "api/api";
import endpoints from "api/URLconstants";
import Loading from "components/Loading/Loading";
import BlockActionAlert from "components/Alerts/BlockActionAlert";
import { useApiMutation } from "hooks/api.hook";
import { useIsComponentLoaded } from "../../hooks/utility.hooks";

function ListResults({ isLoading, dataOrigin }) {
  if (isLoading) {
    return (
      <div className="column">
        <Loading />
      </div>
    );
  }

  if (!dataOrigin.results.length) {
    return (
      <div className="column">
        <BlockActionAlert
          message="No se han encontrado resultados."
          showAction={false}
        />
      </div>
    );
  }

  return dataOrigin.results.map((card, index) => (
    <VenueCard data={card} key={index} />
  ));
}

function AsyncListWrapper({ data, tabs, endpoint }) {
  const created = useIsComponentLoaded();
  const [dataOrigin, setDataOrigin] = useState(data);
  const [activeTab, setActiveTab] = useState(0);
  const [mutate, { isLoading }] = useApiMutation(
    {
      url: endpoint,
      config: {
        params: {
          place_type: !activeTab ? null : activeTab,
        },
      },
    },
    {
      onSuccess: ({ data: results }) => {
        setDataOrigin(results);
      },
    }
  );

  useEffect(() => {
    if (created) {
      mutate();
    }
    //eslint-disable-next-line
  }, [activeTab]);

  return (
    <FilteredList
      title="Espacios"
      tabs={tabs}
      activeTab={activeTab}
      onTabChange={setActiveTab}
      showLoadMore={dataOrigin.next}
    >
      <ListResults isLoading={isLoading} dataOrigin={dataOrigin} />
    </FilteredList>
  );
}

export default function EdificeFilteredList({ id, tabs }) {
  const placesEndpoint = `${endpoints.edifice.list}${id}/places`;
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [placesEndpoint],
    queryFn: (url) => API.get(url),
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <div className="section">
        <div className="container">
          <Loading />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="section">
        <div className="container">
          <BlockActionAlert
            action={refetch}
            message="Hubo un problema al intentar cargar el listado de espacios."
            actionText="Reintentar"
          />
        </div>
      </div>
    );
  }

  return (
    <AsyncListWrapper data={data.data} tabs={tabs} endpoint={placesEndpoint} />
  );
}
