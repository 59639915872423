import React, { useMemo } from "react";

import MainRoute from "./routes";

// context
import UIContextProvider from "contexts/UIContext";
import useResizeObserverHook from "hooks/resizeObserverHook";
import "./assets/css/style.scss";
import "@fortawesome/fontawesome-free/css/solid.min.css"; // Ajuste a la ruta correcta
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function AppWrapper({ children }) {
  const { ref, isMobile } = useResizeObserverHook();

  const memoWrapper = useMemo(() => {
    return (
      <div className={`App ${isMobile ? "mobile" : "non-mobile"}`} ref={ref}>
        {children}
      </div>
    );
  }, [isMobile]);

  return memoWrapper;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UIContextProvider>
        <AppWrapper>
          <MainRoute />
        </AppWrapper>
        <div id="modal-portal"></div>
      </UIContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
